import * as React from "react"
import { Link } from "gatsby"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import { Container, Row, Col } from "react-bootstrap"

// markup
const NotFoundPage = () => {
    return (
        <div className="page-not-found">
            <>
                <Header menuLinks={[]}/>
                <div className="banner">
                    <div className="notfound-page">
                        <Container>
                            <div className="notfound-content">
                                <h1>We’re sorry, the page you were looking for cannot be found.</h1>
                                <p>This maybe because it has been removed, we’ve changed its name or it is temporarily unavailable.</p>
                            </div>
                            <div className="banner-form mb-5">
                                <div className="input-box">
                                    <Link to={`/property/for-sale/`} className="btn subscribe-btn">Find a Property</Link>
                                    <Link to={`/`} className="btn subscribe-btn btn-red">Homepage</Link>
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
                <Footer 
                    popularSearch="Popular_Static" showBreadcrumb = {true}
                />
            </>
        </div>
    )   
}

export default NotFoundPage
